// 登录
export const login = {
  method: 'post',
  url: '/auth/oauth/token?grant_type=password'
}

// 重置密码
export const resetPassword = {
  method: 'put',
  url: '/admin/manage/user/changePassword'
}

// 查询用户应用列表
export const queryUserApps = {
  url: '/admin/manage/user/app'
}

export const uploadFile = {
  method: 'put',
  url: '/admin/file/locality/images'
}

// 新增组织
export const addOrgan = {
  method: 'post',
  url: '/admin/manage/dept'
}

// 删除组织
export const deleteOrgan = {
  method: 'delete',
  url: '/admin/manage/dept/{id}'
}

// 修改组织
export const modifyOrgan = {
  method: 'put',
  url: '/admin/manage/dept'
}

// 查询所有组织树
export const queryOrganTree = {
  url: '/admin/manage/dept/list/tree'
}

// 过滤组织树
export const filterOrganTree = {
  url: '/admin/dept/tree'
}

// 查询组织详情
export const queryOrgan = {
  url: '/admin/manage/dept/{id}'
}

// 查询机构地址信息
export const getDeptAddress = {
  method: 'get',
  url: '/admin/manage/dept/{id}/address'
}

// 编辑机构地址信息
export const editDeptAddress = {
  method: 'post',
  url: '/admin/manage/dept/{id}/address'
}

// 查询机构类型列表
export const getDictItems = {
  url: '/admin/dict/{code}/item/list'
}
// 新增用户
export const addUser = {
  method: 'post',
  url: '/admin/manage/tenant/user'
}

// 删除用户
export const deleteUser = {
  method: 'delete',
  url: '/admin/manage/tenant/user/{id}'
}

// 修改用户
export const modifyUser = {
  method: 'put',
  url: '/admin/manage/tenant/user/{id}'
}

// 用户停用启用
export const updateUserStatus = {
  method: 'put',
  url: '/admin/manage/tenant/user/{id}/status/{status}'
}
// 用户密码重置
export const resetUserPassword = {
  method: 'put',
  url: '/admin/manage/tenant/user/{userId}/reset/password'
}

// 查询用户列表
export const queryUsers = {
  url: '/admin/manage/tenant/user/page'
}

// 修改用户角色列表
export const modifyUserRoles = {
  method: 'put',
  url: '/admin/manage/tenant/user/{id}/assign/roles'
}

// 查询用户角色列表
export const getUserRoles = {
  url: '/admin/manage/role/user/{userId}/roles'
}

// 查询应用角色列表
export const queryAppRoles = {
  url: '/admin/manage/role/{id}/page'
}

// 新增字典
export const addDict = {
  method: 'post',
  url: '/admin/manage/dict'
}

// 删除字典
export const deleteDict = {
  method: 'delete',
  url: '/admin/manage/dict/{id}'
}

// 修改字典
export const modifyDict = {
  method: 'put',
  url: '/admin/manage/dict'
}

// 查询字典列表
export const queryDicts = {
  url: '/admin/manage/dict/page'
}

// 查询字典详情
export const queryDict = {
  url: '/admin/manage/dict/{id}'
}

// 新增字典
export const addDictItem = {
  method: 'post',
  url: '/admin/manage/dict/item'
}

// 删除字典项
export const deleteDictItem = {
  method: 'delete',
  url: '/admin/manage/dict/item/{id}'
}

// 修改字典项
export const modifyDictItem = {
  method: 'put',
  url: '/admin/manage/dict/item'
}

// 查询字典项列表
export const queryDictItems = {
  url: '/admin/manage/dict/item/page'
}

// 用户端首页组件列表
export const componentList = {
  url: '/admin/dict/component/item/list'
}

// 新用户端首页组件列表
export const componentLister = {
  url: '/cms/manage/component/list'
}

// 页面类型列表
export const cmsPageTypeList = {
  url: '/admin/dict/cmsPageType/item/list'
}

// 页面回显详情
export const getManagePageDetail = {
  url: '/cms/manage/page/info/{pageId}'
}

// 页面修改
export const managePageEdit = {
  method: 'put',
  url: '/cms/manage/page'
}

// 页面新增
export const managePageAdd = {
  method: 'post',
  url: '/cms/manage/page'
}

// 页面上下架
export const getManagePageIssue = {
  url: '/cms/manage/page/issue/{pageId}'
}

// 用户端首页组件链接列表
export const resourceTypeList = {
  url: '/admin/dict/resourceType/item/list'
}

// 页面列表
export const getManagePage = {
  url: '/cms/manage/page/page'
}

// 删除页面
export const getManagePageDelete = {
  method: 'delete',
  url: '/cms/manage/page/{pageId}'
}

// 栏目列表
export const navigationList = {
  method: 'get',
  url: '/cms/manage/navigation/search/article'
}
// 栏目树查找
export const navagationTree = {
  method: 'get',
  url: '/cms/manage/navigation/tree'
}

// 根据分类编码查询栏目树
export const navigationAllTree = {
  method: 'get',
  url: '/cms/manage/navigation/list'
}

// 添加栏目信息
export const navigationSave = {
  method: 'post',
  url: '/cms/manage/navigation'
}
// 修改栏目信息
export const navigationUpdate = {
  method: 'put',
  url: '/cms/manage/navigation'
}

// 删除栏目信息
export const navigationDelete = {
  method: 'delete',
  url: '/cms/manage/navigation/{id}'
}
// 查询栏目信息
export const navigationInfo = {
  method: 'get',
  url: '/cms/manage/navigation/{id}'
}

// 02.保存文章
export const addContent = {
  method: 'post',
  url: '/cms/manage/content'
}
// 02.修改文章
export const editContent = {
  method: 'put',
  url: '/cms/manage/content'
}
// 02.内容查询列表分页
export const searchContent = {
  method: 'get',
  url: '/cms/manage/content/search'
}
// 06.内容查询明细
export const cmsManageContent = {
  method: 'get',
  url: '/cms/manage/content/{id}'
}
// 06.内容删除
export const deleteContent = {
  method: 'delete',
  url: '/cms/manage/content/{id}'
}
// 内容发布
export const onlineContent = {
  method: 'put',
  url: '/cms/manage/content/online/{id}/{status}'
}
// 内容发布
export const topContent = {
  method: 'put',
  url: '/cms/manage/content/top/{id}/{status}'
}

// 分页查询内容评论列表
export const evaluatePage = {
  method: 'get',
  url: '/cms/manage/content/evaluate/page'
}

// 删除内容评论
export const evaluateDelete = {
  method: 'delete',
  url: '/cms/manage/content/evaluate/{id}'
}
// 内容排序
export const contentSort = {
  method: 'put',
  url: '/cms/manage/content/sort/{id}/{sort}'
}

// 审核内容评论状态设置
export const updateEvaluateStatus = {
  method: 'put',
  url: '/cms/manage/content/evaluate/{id}/status/{status}'
}
// 设置精选评论状态
export const updateChoiceness = {
  method: 'put',
  url: '/cms/manage/content/evaluate/{id}/choiceness/{status}'
}
// 查询租户评论审核配置信息
export const getEvaluatConfig = {
  method: 'get',
  url: '/cms/manage/evaluate/config'
}
// 租户评论审核配置信息更新
export const evaluateConfigEdit = {
  method: 'post',
  url: '/cms/manage/evaluate/config'
}
// 分页查询CMS轮播图
export const carouselPage = {
  method: 'get',
  url: '/cms/manage/carousel/search'
}
// 添加CMS轮播图
export const addCarousel = {
  method: 'post',
  url: '/cms/manage/carousel'
}
// 更新CMS轮播图
export const updateCarousel = {
  method: 'put',
  url: '/cms/manage/carousel'
}
// CMS轮播图排序
export const sortCarousel = {
  method: 'put',
  url: '/cms/manage/carousel/sort/{id}/{sort}'
}
// 查询CMS轮播图详情
export const getCarouselInfo = {
  method: 'get',
  url: '/cms/manage/carousel/{id}'
}
// 删除CMS轮播图
export const delCarousel = {
  method: 'delete',
  url: '/cms/manage/carousel/{id}'
}
// 分页查询电商轮播图
export const carouselShopPage = {
  method: 'get',
  url: '/cms/manage/page/component/item/page'
}
// 电商轮播图排序
export const shopSortCarousel = {
  method: 'put',
  url: '/cms/manage/page/component/item/sort/{id}/{sort}'
}
// 删除电商轮播图
export const delShopCarousel = {
  method: 'delete',
  url: '/cms/manage/page/component/item/{id}'
}
// 添加电商轮播图
export const addShopCarousel = {
  method: 'post',
  url: '/cms/manage/page/component/item'
}
// 编辑电商轮播图
export const editShopCarousel = {
  method: 'put',
  url: '/cms/manage/page/component/item/{id}'
}
// 商品管理-分页列表
export const productPageManage = {
  method: 'get',
  url: '/goods/manage/product/page'
}
// // 商品分类接口（树）
// export const goyVirtualList = {
//   method: 'get',
//   url: '/goods/goyVirtual/list'
// }

// 查询应用列表
export const queryAppList = {
  url: '/admin/manage/app'
}
// 查询应用下拉
export const queryAppListByTenantId = {
  method: 'get',
  url: '/admin/manage/app/by/tenantId'
}
// 查询应用列表
export const queryApps = {
  url: '/admin/manage/app'
}
// 保存应用
export const saveApp = {
  method: 'post',
  url: '/admin/manage/app'
}
// 应用详情
export const queryAppInfo = {
  url: '/admin/manage/app/{id}/info'
}
// 删除应用
export const removeApp = {
  method: 'delete',
  url: '/admin/manage/app/{id}'
}
// 更新应用
export const updateApp = {
  method: 'put',
  url: '/admin/manage/app'
}
// 删除菜单
export const deleteMenu = {
  method: 'delete',
  url: '/admin/manage/menu/{id}'
}
// 查询树形应用菜单列表
export const queryMenuTreeList = {
  url: '/admin/manage/menu/tree/menu'
}
// 菜单详情
export const queryAppMenuInfo = {
  url: '/admin/manage/menu/{id}'
}
// 保存菜单列表
export const saveAppMenu = {
  method: 'post',
  url: '/admin/manage/menu'
}

/* ------------------------------------- */
// 查询应用菜单列表
export const queryAppMenuList = {
  url: '/admin/manage/menu/tree/menu'
}

// 更新菜单
export const updateAppMenu = {
  method: 'put',
  url: '/admin/manage/menu'
}

// 查询应用角色列表
export const queryAppRoleList = {
  url: '/admin/manage/role'
}
// 查询应用角色详情
export const queryAppRoleInfo = {
  url: '/admin/manage/role/{id}/info'
}
// 保存应用角色
export const saveAppRole = {
  method: 'post',
  url: '/admin/manage/role'
}
// 更新应用角色
export const updateAppRole = {
  method: 'put',
  url: '/admin/manage/role'
}
// 删除应用角色
export const removeAppRole = {
  method: 'delete',
  url: '/admin/manage/role/{id}'
}
/* --------------- */
// 查询租户列表
export const queryTenants = {
  url: '/admin/manage/tenant'
}
// 删除租户
export const deleteTenant = {
  method: 'delete',
  url: '/admin/manage/tenant/{id}'
}
// 租户停用启用
export const updateTenantStatus = {
  method: 'put',
  url: '/admin/manage/tenant/{id}/lock/{status}'
}
// 查询省市区三级数据
export const getProvinceCityCounty = {
  url: '/admin/district/province/city/county/list'
}
// 按上级查询下级地区列表
export const ChildAdres = {
  method: 'get',
  url: '/admin/district/{id}/child'
}
// 分组查省市区
export const districtGrouplist = {
  method: 'get',
  url: '/admin/districtGroup/list'
}
// 查询租户明细
export const getTenantInfo = {
  method: 'get',
  url: '/admin/manage/tenant/{id}'
}
// 保存租户信息
export const saveTenant = {
  method: 'post',
  url: '/admin/manage/tenant'
}
// 更新租户信息
export const updateTenant = {
  method: 'put',
  url: '/admin/manage/tenant'
}
// 查询应用角色列表
export const getAppRoleList = {
  method: 'get',
  url: '/admin/manage/app/roles'
}
// 分配应用角色
export const saveAppRoles = {
  method: 'put',
  url: '/admin/manage/tenant/{id}/app/role'
}
// 查询管理员角色列表
export const getManageRoles = {
  method: 'get',
  url: '/admin/manage/tenant/{id}/app/role'
}
// 查询前端用户列表
export const queryUserFrontList = {
  method: 'get',
  url: '/admin/manage/user/front/page'
}
// 订单列表
export const orderList = {
  url: '/order/manage/order/page'
}
// 订单详情
export const orderDetail = {
  url: '/order/manage/order/{orderNo}/detail'
}
// 订单拆单
export const orderSplitItem = {
  method: 'put',
  url: '/order/manage/order/{orderNo}/split'
}
// 订单取货记录列表
export const getOrderFetchItem = {
  method: 'get',
  url: '/order/manage/fetch/{orderNo}/fetch/item'
}
// 订单物料信息列表
export const getOrderLogistislItem = {
  method: 'get',
  url: '/order/kuaidi100/data'
}

// 自提点分页查询
export const selfLiftingPointList = {
  method: 'get',
  url: '/order/manage/fetch'
}

// 自提点添加
export const selfLiftingPointAdd = {
  method: 'post',
  url: '/order/manage/fetch'
}

// 自提点修改
export const selfLiftingPointEdit = {
  method: 'put',
  url: '/order/manage/fetch'
}

// 自提点删除
export const selfLiftingPointDelete = {
  method: 'delete',
  url: '/order/manage/fetch/{id}'
}

// 自提点详情
export const selfLiftingPointInfo = {
  method: 'get',
  url: '/order/manage/fetch/{id}'
}

// 运费模板列表
export const freightTempList = {
  method: 'get',
  url: '/order/manage/delivery'
}

// 运费模板详情
export const freightTempInfo = {
  method: 'get',
  url: '/order/manage/delivery/{id}'
}

// 运费模板新增
export const freightTempAdd = {
  method: 'post',
  url: '/order/manage/delivery'
}

// 运费模板编辑
export const freightTempEdit = {
  method: 'put',
  url: '/order/manage/delivery'
}

// 运费模板删除
export const freightTempDelete = {
  method: 'delete',
  url: '/order/manage/delivery/{id}'
}

// 标记工作位置
// 标记工作位置
// 标记工作位置
// 标记工作位置
// 标记工作位置
// 标记工作位置

// 售后列表
export const afterSaleOrder = {
  url: '/order/manage/order/sale'
}
// 售后历史列表
export const hisAfterSaleOrder = {
  url: '/order/manage/order/sale/his'
}
// 退货收货地址查询
export const getTenantAddress = {
  method: 'get',
  url: '/order/manage/tenant/address'
}
// 退货收货地址编辑
export const editTenantAddress = {
  method: 'post',
  url: '/order/manage/tenant/address'
}
// 退货收货地址删除
export const deleteTenantAddress = {
  method: 'delete',
  url: '/order/manage/tenant/address/{id}'
}
// 售后退货单同意退货操作
export const agreeReturnAfterSale = {
  method: 'put',
  url: '/order/manage/order/sale/{afterSaleId}/agree/return'
}
// 售后退货单确认收货
export const confirmReceiptAfterSale = {
  method: 'put',
  url: '/order/manage/order/sale/{afterSaleId}/receipt'
}
// 售后添加备注
export const addAfterSaleMemo = {
  method: 'put',
  url: '/order/manage/order/sale/{afterSaleId}/memo'
}
// 售后审核
export const auditAfterSale = {
  method: 'put',
  url: '/order/manage/order/sale/{afterSaleId}/audit'
}
// 商品分类删除
export const goodsGoyGoodsDelete = {
  method: 'delete',
  url: '/goods/manage/goods/type/{id}'
}
// 商品分类详情
export const goyGoodsInfo = {
  method: 'get',
  url: '/goods/manage/goods/type/{id}'
}

// 商品分类列表
export const goodsGoyGoodsList = {
  method: 'get',
  url: '/goods/manage/goods/type/list'
}

// 商品分类上级分类列表   /goods/manage/goyGoods/goyGoodsList <<<<< /goods/manage/goyGoods/parentList

// 商品分类添加
export const goodsGoyGoodsAdd = {
  method: 'post',
  url: '/goods/manage/goods/type'
}
// 商品分类修改
export const goodsGoyGoodsUpdate = {
  method: 'put',
  url: '/goods/manage/goods/type'
}
// 搜索业务类型列表
export const businessTypePage = {
  method: 'get',
  url: '/goods/manage/business/type/page'
}
// 搜索业务类型列表
export const businessTypeList = {
  method: 'get',
  url: '/goods/manage/business/type/list'
}
// 虚拟分类删除
export const goyVirtualDelete = {
  method: 'delete',
  url: '/goods/manage/virtual/{id}'
}
// 虚拟分类列表
export const goyVirtualList = {
  method: 'get',
  url: '/goods/manage/virtual/tree'
}
// 虚拟分类添加
export const goyVirtualAdd = {
  method: 'post',
  url: '/goods/manage/virtual'
}
// 虚拟分类修改
export const goyVirtualUpdate = {
  method: 'put',
  url: '/goods/manage/virtual'
}

// 添加业务类型
export const businessTypeListAdd = {
  method: 'post',
  url: '/goods/manage/business/type'
}
// 编辑业务类型
export const businessTypeListEdit = {
  method: 'put',
  url: '/goods/manage/business/type'
}

// 编辑业务类型
export const businessTypeListdelete = {
  method: 'delete',
  url: '/goods/manage/business/type/{id}'
}
// 查询业务类型
export const getBusinessTypeInfo = {
  method: 'get',
  url: '/goods/manage/business/type/{id}'
}
// 发票列表
export const invoiceList = {
  url: '/order/manage/invoice/page'
}
// 查询发票关联订单信息
export const invoiceOrderNos = {
  method: 'get',
  url: '/order/manage/invoice/{invoiceId}/order'
}
// 查询发票状态列表
export const invoiceStatusList = {
  method: 'get',
  url: '/order/manage/invoice/status'
}
// 删除发票
export const removeInvoice = {
  method: 'delete',
  url: '/order/manage/invoice/{invoiceId}'
}
// 发票审核驳回
export const auditOrReject = {
  method: 'put',
  url: '/order/manage/invoice/{invoiceId}'
}
// 订单审核驳回
export const auditOrder = {
  method: 'put',
  url: '/order/manage/order/audit/{orderNo}'
}
// 订单发货
export const oderDeliver = {
  method: 'put',
  url: '/order/manage/order/{orderNo}/deliver'
}
// 渠道会员等级列表
export const memberGradeList = {
  url: '/admin/manage/member'
}
// 渠道会员等级保存
export const saveMemberGrade = {
  method: 'post',
  url: '/admin/manage/member'
}
// 渠道会员等级删除
export const deleteMemberGrade = {
  method: 'delete',
  url: '/admin/manage/member/{id}'
}
// 渠道会员等级修改
export const editMemberGrade = {
  method: 'put',
  url: '/admin/manage/member'
}
// 租户列表
export const tenantList = {
  url: '/admin/manage/tenant/list'
}

// 商品分类规格列表分页（新）
export const getSpecList = {
  method: 'get',
  url: '/goods/manage/spec/page'
}

// 商品分类规格删除
export const deleteSpec = {
  method: 'delete',
  url: '/goods/manage/spec/{id}'
}

// 商品分类规格查询
export const getSpecInfo = {
  method: 'get',
  url: '/goods/manage/spec/{id}'
}

// 商品分类规格添加
export const addSpec = {
  method: 'post',
  url: '/goods/manage/spec'
}
// 商品分类规格修改
export const updateSpec = {
  method: 'put',
  url: '/goods/manage/spec'
}

// 商品分类属性删除
export const goyGoodsPropertyDelete = {
  method: 'delete',
  url: '/goods/manage/property/{id}'
}
// 商品分类属性查询
export const getPropertyInfo = {
  method: 'get',
  url: '/goods/manage/property/{id}'
}
// 商品分类属性添加
export const goyGoodsPropertyAdd = {
  method: 'post',
  url: '/goods/manage/property'
}
// 商品分类属性修改
export const goyGoodsPropertyUpdate = {
  method: 'put',
  url: '/goods/manage/property'
}
// 商品分类属性列表
export const getPropertyList = {
  method: 'get',
  url: '/goods/manage/property/page'
}

// 查询品牌列表
export const goBrandList = {
  method: 'get',
  url: '/goods/manage/brand/list'
}

// 商品分类规格查找
export const goySpecValueVueGoyGoodsId = {
  method: 'get',
  url: '/goods/manage/goods/type/{id}/spec'
}

// 商家删除平台的规格自定义值
export const delCustomizeSpec = {
  method: 'delete',
  url: '/goods/manage/spec/delCustomizeSpec/{goSpecId}'
}
// 商品分类规格删除
export const goySpecificationDelete = {
  method: 'delete',
  url: '/goods/manage/spec/{id}'
}

// 商家给平台的属性添加自定义值
export const goyPropertycustomizeProperty = {
  method: 'post',
  url: '/goods/manage/property/customizeProperty'
}
// 商家给平台的规格添加自定义值
export const goySpecificationcustomizeSpec = {
  method: 'post',
  url: '/goods/manage/spec/customizeSpec'
}
// 单位字典
export const unitItemList = {
  url: '/admin/dict/unit/item/list'
}
// 特性分组
export const propertyTypeGroup = {
  url: '/admin/dict/propertyTypeGroup/item/list'
}
// 输入类型
export const inputTypeList = {
  url: '/admin/dict/inputType/item/list'
}
// 商品标签字典
export const goodsLabelsList = { // 正品  假一赔十  七天保障
  url: '/admin/dict/goodsLabels/item/list'
}
// 商品管理 添加商品
export const saveGoGoods = {
  method: 'post',
  url: '/goods/manage/goods/normal'
}
// 商品管理 编辑商品
export const editGoods = {
  method: 'put',
  url: '/goods/manage/goods/normal'
}
// 商品管理 编辑商品排序
export const editGoodsSort = {
  method: 'put',
  url: '/goods/goods/{goodsId}/sort/{sort}'
}
// 商品管理 编辑商品
export const goodOnsaleList = {
  method: 'get',
  url: '/goods/manage/goods/normal/page'
}
// 商品管理 查询商品详情
export const getGoodInfo = {
  method: 'get',
  url: '/goods/manage/goods/normal/{id}'
}
// 商品管理 商品库存、价格查询
export const getGoodInfoDetail = {
  method: 'get',
  url: '/goods/manage/goods/normal/{id}/product/detail'
}

// 商品管理 删除商品
export const goodsDelete = {
  method: 'delete',
  url: '/goods/manage/goods/normal/{id}'
}

// 商品上下架
export const goodsOnSell = {
  method: 'put',
  url: '/goods/manage/goods/normal/{id}/online/{online}'
}

// 商品管理/审核-分页列表
export const goGoodsPageManage = {
  method: 'get',
  url: '/goods/manage/goods/normal/page'
}

// 搜索业特性列表
export const businessProperties = {
  method: 'get',
  url: '/goods/manage/business/property/page'
}
// 搜索业特性列表
export const businessPropertiesList = {
  method: 'get',
  url: '/goods/manage/business/property/list'
}
// 搜索业特性列表
export const businessPropertiesInfo = {
  method: 'get',
  url: '/goods/manage/business/property/{id}'
}
// 添加业特性
export const businessPropertiesAdd = {
  method: 'put',
  url: '/goods/manage/business/property/save'
}
// 编辑业特性
export const businessPropertiesEdit = {
  method: 'put',
  url: '/goods/manage/business/property/update'
}

// 删除业特性f
export const businessPropertiesdelete = {
  method: 'delete',
  url: '/goods/manage/business/property/{id}'
}
// 调整商品价格
export const editGoodsPrice = {
  method: 'put',
  url: '/goods/manage/goods/normal/{id}/price'
}
// 调整商品价格
export const editGoodsStack = {
  method: 'put',
  url: '/goods/manage/goods/normal/{id}/stock'
}
// 品牌管理列表
export const goBrandPageList = {
  method: 'get',
  url: '/goods/manage/brand/page'
}
// 查询品牌信息
export const getGoBrandInfo = {
  method: 'get',
  url: '/goods/manage/brand/{id}'
}
// 编辑品牌
export const goBrandSaveOrUpdate = {
  method: 'put',
  url: '/goods/manage/brand'
}

// 添加品牌
export const goBrandSaveOrAdd = {
  method: 'post',
  url: '/goods/manage/brand'
}
// 删除品牌
export const deleteGoBrand = {
  method: 'delete',
  url: '/goods/manage/brand/{id}'
}
// 查询商品分类下属性分类及属性值
export const propertyAtGoyGoods = {
  method: 'get',
  url: '/goods/manage/goods/type/{id}/property'
}
// 分页查询关键字列表
export const goodsKeywordPage = {
  method: 'get',
  url: '/goods/manage/keyword/page'
}
// 添加关键字
export const addGoodsKeyword = {
  method: 'post',
  url: '/goods/manage/keyword'
}
// 更新关键字
export const updateGoodsKeyword = {
  method: 'put',
  url: '/goods/manage/keyword'
}
// 删除关键字
export const delGoodsKeyword = {
  method: 'delete',
  url: '/goods/manage/keyword/{id}'
}
// 查询凭证列表信息
export const queryPayVoucher = {
  url: '/pay/manage/voucher'
}

// 凭证审核
export const auditPayVoucher = {
  method: 'post',
  url: '/pay/manage/voucher/audit'
}

// 商品分类属性修改
export const goyPropertyUpdate = {
  method: 'put',
  url: '/goods/manage/property/update'
}

// 分页查询版面信息
export const cmsManageSceneSearch = {
  method: 'get',
  url: '/cms/manage/scene/search'
}
// 更新版面信息
export const updateScene = {
  method: 'put',
  url: '/cms/manage/scene'
}
// 添加版面信息
export const addScene = {
  method: 'post',
  url: '/cms/manage/scene'
}
// 添加版面信息
export const deleteScene = {
  method: 'delete',
  url: '/cms/manage/scene/{id}'
}
// 查询版面列表
export const cmsManageSceneList = {
  method: 'get',
  url: '/cms/manage/scene/list'
}
// 账户管理列表
export const queryAccount = {
  method: 'get',
  url: '/account/manage/account/page'
}
// 账户审核
export const updateAccountStatus = {
  method: 'put',
  url: '/account/manage/account/{id}/status/{status}'
}
// 账户提现管理列表
export const queryWithDrawal = {
  method: 'get',
  url: '/account/manage/withdrawal/page'
}
// 账户提现审核通过
export const withdrawalAuditPass = {
  method: 'put',
  url: '/account/manage/withdrawal/audit/pass/{id}'
}
// 账户提现审核驳回
export const withdrawalAuditReject = {
  method: 'put',
  url: '/account/manage/withdrawal/audit/reject/{id}'
}
// 账户提现打款
export const withdrawalPay = {
  method: 'put',
  url: '/account/manage/withdrawal/pay'
}
// 账户管理明细列表
export const queryAccountRecord = {
  method: 'get',
  url: '/account/manage/account/record/page'
}
// 账户交易类型列表
export const recordTypeList = {
  method: 'get',
  url: '/account/manage/account/record/type'
}
// 账户管理结算卡列表
export const queryAccountCard = {
  method: 'get',
  url: '/account/manage/card/{accountId}'
}
// 优惠券列表
export const couponList = {
  method: 'get',
  url: '/promotion/manage/coupon/normal/page'
}
// 优惠券基本信息
export const getCouponInfo = {
  method: 'get',
  url: '/promotion/manage/coupon/normal/base/{id}'
}
// 优惠券新增
export const couponAdd = {
  method: 'post',
  url: '/promotion/manage/coupon/normal'
}
// 优惠券修改
export const couponUpdate = {
  method: 'put',
  url: '/promotion/manage/coupon/normal'
}
// 优惠券删除
export const removeCoupon = {
  method: 'delete',
  url: '/promotion/manage/coupon/normal/{id}'
}
// 查询优惠券
export const getCouponDetail = {
  method: 'get',
  url: '/promotion/manage/coupon/normal/{id}'
}
// 优惠券修改
export const couponUpdateStatus = {
  method: 'put',
  url: '/promotion/manage/coupon/normal/{couponId}/status/{status}'
}
// 优惠券设置查询
export const getCouponConfig = {
  method: 'get',
  url: '/promotion/manage/coupon/config'
}
// 优惠券设置编辑
export const couponConfigEdit = {
  method: 'post',
  url: '/promotion/manage/coupon/config'
}
// 优惠券券码列表
export const queryCouponItems = {
  method: 'get',
  url: '/promotion/manage/coupon/{couponId}/item/page'
}
// 优惠券券码作废
export const couponCancel = {
  method: 'put',
  url: '/promotion/manage/coupon/{couponId}/item/{couponCode}/cancel'
}
// 生产优惠券券码
export const generateItemAdd = {
  method: 'post',
  url: '/promotion/manage/coupon/normal/{couponId}/generate/item/{qty}'
}
// 当前店铺下上架商品
export const goodsStoreList = {
  method: 'get',
  url: '/goods/manage/product/sale/list'
}
// 优惠券条件类型下拉列表
export const conditionTypeList = {
  method: 'get',
  url: '/promotion/manage/coupon/condition/type/list'
}
// 优惠券类型下拉列表
export const couponTypeList = {
  method: 'get',
  url: '/promotion/manage/coupon/type/list'
}
// 优惠券特性查询
export const propertyGroupByCouponType = {
  method: 'get',
  url: '/promotion/manage/type/{id}'
}
// 优惠券指定商品查询
export const goodsForCoupon = {
  method: 'get',
  url: '/goods/manage/product/page/promotion'
}
// 优惠券指定商品分组查询
export const goodsGroupForCoupon = {
  method: 'get',
  url: '/goods/manage/virtual/search'
}
// 当前店铺下分类
export const GoyStoreList = {
  method: 'get',
  url: '/goods/manage/goods/type/end/list'
}
// 查询机柜列表
export const querySaleDevice = {
  method: 'get',
  url: '/sale/manage/device/page'
}
// 删除机柜
export const deleteMeDevice = {
  method: 'delete',
  url: '/sale/manage/device/{id}'
}
// 取消机柜分配驿站
export const cancelStage = {
  method: 'put',
  url: '/sale/manage/device/cancel/{id}'
}
// 查询驿站列表
export const getStageList = {
  method: 'get',
  url: '/admin/manage/dept/stage/list'
}
// 机柜分配驿站
export const assignStage = {
  method: 'put',
  url: '/sale/manage/device/assign/{id}'
}
// 新增机柜
export const addDevice = {
  method: 'post',
  url: '/sale/manage/device'
}
// 更新机柜
export const modifyDevice = {
  method: 'put',
  url: '/sale/manage/device/{id}'
}
// 查询货品奖励池列表
export const getSaleProductConfig = {
  method: 'get',
  url: '/sale/manage/sale/product/config/list'
}
// 添加或更新货品奖励池
export const editSaleProductConfig = {
  method: 'post',
  url: '/sale/manage/sale/product/config'
}
// 查询分销大使奖励设置
export const getSaleAward = {
  method: 'get',
  url: '/sale/manage/sale/award/config'
}
// 保存分销大使奖励设置
export const saveSaleAward = {
  method: 'post',
  url: '/sale/manage/sale/award/config'
}
// 查询分销驿站奖励设置
export const getStageAward = {
  method: 'get',
  url: '/sale/manage/stage/award/config'
}
// 保存分销驿站奖励设置
export const saveStageAward = {
  method: 'post',
  url: '/sale/manage/stage/award/config'
}
// 查询分销公社奖励设置
export const getCommuneAward = {
  method: 'get',
  url: '/sale/manage/commune/award/config'
}
// 保存分销公社奖励设置
export const saveCommuneAward = {
  method: 'post',
  url: '/sale/manage/commune/award/config'
}
// 查询分销宣传关系设置
export const getSalePublicity = {
  method: 'get',
  url: '/sale/manage/publicity/config'
}
// 保存分销宣传关系设置
export const saveSalePublicity = {
  method: 'post',
  url: '/sale/manage/publicity/config'
}
// 查询分销销售关系设置
export const getSaleSigner = {
  method: 'get',
  url: '/sale/manage/signer/config'
}
// 保存分销销售关系设置
export const saveSaleSigner = {
  method: 'post',
  url: '/sale/manage/signer/config'
}
// 查询大使、驿站、公社邀请驿站账户列表
export const addStageAccountList = {
  method: 'get',
  url: '/sale/manage/relation/{inviteScene}/account/page'
}
// 查询公社大使列表
export const communeSaleList = {
  method: 'get',
  url: '/sale/manage/relation/communeSaleJoin/account/page'
}
// 查询没有邀请、加入关系驿站列表
export const listByUnlistedStage = {
  method: 'get',
  url: '/sale/manage/relation/{inviteScene}/unlisted/stage'
}
// 大使、驿站、公社邀请驿站
export const addStage = {
  method: 'post',
  url: '/sale/manage/relation/{inviteScene}/{inviteId}/stage/{beInviteId}'
}
// 公社移除驿站
export const communeRemoveStage = {
  method: 'delete',
  url: '/sale/manage/relation/commune/{inviteId}/stage/{beInviteId}'
}
// 查询大使、驿站邀请用户列表
export const querySaleUserList = {
  method: 'get',
  url: '/sale/manage/relation/{inviteScene}/user/page'
}
// 分页查询大使、驿站邀请统计
export const queryInvitationStatistics = {
  method: 'get',
  url: '/sale/manage/relation/{beInviteType}/statistics/page'
}
// 分页查询被邀请大使列表
export const queryBeInvitedSaleList = {
  method: 'get',
  url: '/sale/manage/relation/{inviteScene}/sale/page'
}
// 分页查询被邀请驿站列表
export const queryBeInvitedStageList = {
  method: 'get',
  url: '/sale/manage/relation/{inviteScene}/stage/page'
}
// 分页查询大使、驿站分销统计
export const queryDistributionStatistics = {
  method: 'get',
  url: '/sale/manage/relation/{accountType}/distribution/statistics/page'
}
// 分页查询公社分销统计
export const queryCommuneStatistics = {
  method: 'get',
  url: '/sale/manage/relation/commune/distribution/statistics/page'
}
// 查询账户提现金额设置
export const getWithdrawalConfig = {
  method: 'get',
  url: '/account/manage/tenant/config'
}
// 保存账户提现金额设置
export const savetWithdrawalConfig = {
  method: 'put',
  url: '/account/manage/tenant/config'
}

// 分销员列表
export const distributorsList = {
  url: '/sale/manage/channel/user/sale'
}

// 分销员添加
export const addDistributor = {
  method: 'post',
  url: '/admin/manage/user/grade/sale'
}

// 分销员编辑
export const editDistributor = {
  method: 'put',
  url: '/admin/manage/user/grade/sale'
}

// 团队列表
export const teamList = {
  url: '/sale/manage/channel/team'
}

// 团队添加
export const addTeam = {
  method: 'post',
  url: '/sale/manage/channel/team'
}

// 团队编辑
export const editTeam = {
  method: 'put',
  url: '/sale/manage/channel/team'
}

// 团队奖励配置查询
export const getTeamAward = {
  url: '/sale/manage/channel/team/award/{channelId}'
}

// 团队奖励配置新增和修改
export const addEditTeamAward = {
  method: 'post',
  url: '/sale/manage/channel/team/award/{channelId}'
}

// 团队分销员奖励配置查询
export const getTeamSaleAward = {
  url: '/sale/manage/channel/team/award/{channelId}/grade/sale'
}

// 团队分销员奖励配置
export const editTeamSaleAward = {
  method: 'post',
  url: '/sale/manage/channel/team/award/{channelId}/grade/sale'
}
// 查询渠道列表
export const getChannelList = {
  method: 'get',
  url: '/sale/manage/channel/team'
}
// 分页查询销售方列表
export const querySellerList = {
  method: 'get',
  url: '/pay/manage/seller'
}
// 销售方停用/启用
export const updateSellerStatus = {
  method: 'put',
  url: '/pay/manage/seller/{id}/enabled/{enabled}'
}
// 查询支付公司列表
export const getCompanyList = {
  method: 'get',
  url: '/pay/manage/seller/pay/company'
}
// 新增用户
export const addSeller = {
  method: 'post',
  url: '/pay/manage/seller'
}
// 修改用户
export const modifySeller = {
  method: 'put',
  url: '/pay/manage/seller'
}

// 资源管理
// 资源管理
// 资源管理
// 资源管理
// 01.资源分组列表查询
export const resourceGroupList = {
  method: 'get',
  url: '/admin/manage/resource/group/list'
}
// 02.资源分组添加
export const resourceGroupAdd = {
  method: 'post',
  url: '/admin/manage/resource/group'
}
// 03.资源分组更新
export const resourceGroupEdit = {
  method: 'put',
  url: '/admin/manage/resource/group'
}
// 04.资源分组删除
export const resourceGroupDelete = {
  method: 'delete',
  url: '/admin/manage/resource/group/{id}'
}
// 05.资源分页查询
export const resourcePage = {
  method: 'get',
  url: '/admin/manage/resource/page'
}
// 06.资源添加或更新
export const resourceEdit = {
  method: 'post',
  url: '/admin/manage/resource'
}
// 广告分页查询
export const advertPage = {
  method: 'get',
  url: '/cms/manage/advertising/page'
}
// 添加广告
export const addAdvert = {
  method: 'post',
  url: '/cms/manage/advertising'
}
// 更新广告
export const editAdvert = {
  method: 'put',
  url: '/cms/manage/advertising'
}
// 删除广告
export const deleteAdvert = {
  method: 'delete',
  url: '/cms/manage/advertising/{id}'
}
